import * as React from "react";

import { GreenzineApp } from "@coderehab/greenzine-react-deprecated";
import { Themes } from "@coderehab/greenzeen-themes";
import { Content } from "@coderehab/greenzeen-content";

import "./app.css";
import { amplify } from "./config/amplify";
import "./style/fonts.css";

const tenant = process.env.REACT_APP_CLIENT_THEME || "default";
const config = {
  aws: amplify,
};

Themes.configure({ tenant });
Content.configure({ tenant });

const App: React.FC = () => {
  return <GreenzineApp config={config} tenant={tenant}></GreenzineApp>;
};

export default App;
